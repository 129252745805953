import React from 'react';

const DemoPage = () => {
  return (
    <div className="container">
      <div className="w-[100px] h-[100px] bg-cf-primary rounded my-2" />
      <div className="w-[100px] h-[100px] bg-cf-green rounded-full my-2" />
      <div>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab, animi et
        impedit placeat quisquam sit ut voluptate! Alias delectus dolorem ea et
        praesentium. Est fugit ipsa sequi, sit totam voluptates.
      </div>
      <button type="button" className="btn btn--green">
        testing
        <i className="cf-icon cf-chevron-btn" />
      </button>
    </div>
  );
};

export default DemoPage;
